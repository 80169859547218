module.exports = function hero(elem) {
    const BG_img = new Image();
    BG_img.src = "./assets/images/bg.jpg";

    const cloud1 = new Image();
    cloud1.src = "./assets/images/cloud1.png";

    const cloud2 = new Image();
    cloud2.src = "./assets/images/cloud2.png";

    const cloud3 = new Image();
    cloud3.src = "./assets/images/cloud3.png";

    const cloud4 = new Image();
    cloud4.src = "./assets/images/cloud4.png";

    const cloud5 = new Image();
    cloud5.src = "./assets/images/cloud5.png";

    const clouds = document.querySelectorAll('.clouds img')
    BG_img.onload = () => {
        document
            .querySelector(".hero")
            .setAttribute("style", `background-image: url(${BG_img.src});`);
        // document.querySelector(".hero").classList.add("loaded");
    };

    // cloud1.onload = () => {
    //     clouds[0]
    //         .setAttribute("src", `${cloud1.src}`);
    //     clouds[5]
    //         .setAttribute("src", `${cloud1.src}`);
    // };

    // cloud2.onload = () => {
    //     clouds[1]
    //         .setAttribute("src", `${cloud1.src}`);
    //     clouds[6]
    //         .setAttribute("src", `${cloud1.src}`);
    // };

    // cloud3.onload = () => {
    //     clouds[2]
    //         .setAttribute("src", `${cloud1.src}`);
    //     clouds[7]
    //         .setAttribute("src", `${cloud1.src}`);
    // };

    // cloud4.onload = () => {
    //     clouds[3]
    //         .setAttribute("src", `${cloud1.src}`);
    //     clouds[8]
    //         .setAttribute("src", `${cloud1.src}`);
    // };

    // cloud5.onload = () => {
    //     clouds[4]
    //         .setAttribute("src", `${cloud1.src}`);
    //     clouds[9]
    //         .setAttribute("src", `${cloud1.src}`);
    // };

};