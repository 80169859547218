module.exports = function about() {
    const mount1 = new Image();
    mount1.src = "./assets/images/mount_0.jpg";

    const mount2 = new Image();
    mount2.src = "./assets/images/bg.jpg";

    const mounts = document.querySelectorAll('.parallax-window')

    mount1.onload = () => {
        mounts[0]
            .setAttribute("data-image-src", `${mount1.src}`);
        // document.querySelector(".hero").classList.add("loaded");
    };
};
